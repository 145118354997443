<template>
  <div class="inquiry">
    <h3>屈光发育史：</h3>
    <div class="qgfys">
      <p>近视年龄</p>
      <el-input class="sr" v-model="postdata.S_OP_Json.CL_Inquiry_MyopiaAge" placeholder="请输入内容"></el-input>
      <span>岁</span>

      <p>每年增长幅度</p>
      <el-input class="sr" v-model="postdata.S_OP_Json.CL_Inquiry_MyopiaAdd" placeholder="请输入内容"></el-input>


      <p>矫正方案</p>
      <el-select class="sr" v-model="postdata.S_OP_Json.CL_Inquiry_CorrectType" style="margin-right: 10px" placeholder="请选择">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <el-input  class="sr" v-model="postdata.S_OP_Json.CL_Inquiry_CorrectYear" placeholder="请输入内容"></el-input>
      <span>年</span>
    </div>

    <h3>眼部病史：</h3>
    <div class="ybbs">
      <div class="ybbs-box">
        <div class="ybbs-box-nr">
          <p>是否有角膜炎</p>
          <el-radio v-model="postdata.S_OP_Json.CL_Inquiry_Keratitis" label="1">是</el-radio>
          <el-radio v-model="postdata.S_OP_Json.CL_Inquiry_Keratitis" label="0">否</el-radio>
        </div>
        <div class="ybbs-box-nr">
          <p>是否有鼻炎/鼻窦炎</p>
          <el-radio v-model="postdata.S_OP_Json.CL_Inquiry_Rhinitis" label="1">是</el-radio>
          <el-radio v-model="postdata.S_OP_Json.CL_Inquiry_Rhinitis" label="0">否</el-radio>
        </div>
        <div class="ybbs-box-nr">
          <p>是否有过敏性结膜炎</p>
          <el-radio v-model="postdata.S_OP_Json.CL_Inquiry_AllergicConjunctivitis" label="1">是</el-radio>
          <el-radio v-model="postdata.S_OP_Json.CL_Inquiry_AllergicConjunctivitis" label="0">否</el-radio>
        </div>
      </div>
      <div class="ybbs-box">
        <div class="ybbs-box-nr">
          <p>是否有慢性泪囊炎</p>
          <el-radio v-model="postdata.S_OP_Json.CL_Inquiry_ChronicDacryocystitis" label="1">是</el-radio>
          <el-radio v-model="postdata.S_OP_Json.CL_Inquiry_ChronicDacryocystitis" label="0">否</el-radio>
        </div>
        <div class="ybbs-box-nr">
          <p>是否有外伤</p>
          <el-radio v-model="postdata.S_OP_Json.CL_Inquiry_Injury" label="1">是</el-radio>
          <el-radio v-model="postdata.S_OP_Json.CL_Inquiry_Injury" label="0">否</el-radio>
        </div>
        <div class="ybbs-box-nr">
          <p>是否有眼干症</p>
          <el-radio v-model="postdata.S_OP_Json.CL_Inquiry_Xeroma" label="1">是</el-radio>
          <el-radio v-model="postdata.S_OP_Json.CL_Inquiry_Xeroma" label="0">否</el-radio>
        </div>
      </div>
    </div>

    <div class="qtjbs">
      <h3>其他疾病史长期用药史：</h3>
      <el-radio v-model="postdata.S_OP_Json.CL_Inquiry_GeneralMedical" label="1">是</el-radio>
      <el-radio v-model="postdata.S_OP_Json.CL_Inquiry_GeneralMedical" label="0">否</el-radio>
      <el-input class="sr" v-model="postdata.S_OP_Json.CL_Inquiry_GeneralMedicalDetail" placeholder="请输入内容"></el-input>
    </div>

    <div class="qtjbs">
      <h3>过敏史及药物过敏史：</h3>
      <el-radio v-model="postdata.S_OP_Json.CL_Inquiry_AllergicHistory" label="1">是</el-radio>
      <el-radio v-model="postdata.S_OP_Json.CL_Inquiry_AllergicHistory" label="0">否</el-radio>
      <el-input class="sr" v-model="postdata.S_OP_Json.CL_Inquiry_AllergicHistoryDetail" placeholder="请输入内容"></el-input>
    </div>

    <div class="qtjbs">
      <h3>特殊用眼场景：</h3>
      <el-checkbox-group v-model="checkList">
        <el-checkbox label="复选框 A">风沙、灰尘</el-checkbox>
        <el-checkbox label="复选框 B">高挥发性酸碱环境</el-checkbox>
      </el-checkbox-group>
    </div>

    <div class="qtjbs">
      <h3>备注：</h3>
      <el-input style="width: 440px" v-model="input" placeholder="请输入内容"></el-input>
    </div>

    <div class="db">
      <el-button>返回</el-button>
      <el-button type="primary">确定</el-button>
    </div>
  </div>
</template>
jq
<script>
import pf from "@/publicFn/baseFn"
export default {
  name: "inquiry",
  data() {
    return {
      input: '',
      radio: '',
      checkList: [],
      options: [
        {
          label: '框架',
          value: '框架'
        },
        {
          label: 'SCL',
          value: 'SCL'
        },
        {
          label: 'RGP',
          value: 'RGP'
        },
        {
          label: 'OK镜',
          value: 'OK镜'
        },
        {
          label: '其他',
          value: '其他'
        }
      ],
      postdata: {S_OP_Json: {}}
    }
  },

  created() {
    console.log(pf.getUrl('PS_all_Normal_getList'))
    this._http.post(pf.getUrl('PS_all_Normal_getList'), {
      token: {sToken : 'A97D17F7740884C1F5B313C12A9FC5C0', 'TimeSpan' : '1682479352' },
      'SearchCondition' : {
        S_OP_Type : 'CL_Inquiry',
        ChooseId: '295a90d1-177b-4f4e-8fd9-34a0c2d06281'
      }
    })
    .then(res => {
      // console.log(res)
      if (res.GetListResult && res.GetListResult.length) {
        this.postdata = res.GetListResult[0]
        this.postdata.S_OP_Json = pf.decoding(this.postdata.S_OP_Json)
        // console.log(this.postdata)
      }
    })
  }
}
</script>

<style scoped lang="scss">
.inquiry {
  width: 96vw;
  height: 87vh;
  overflow-y: auto;
  padding-left: 4vw;
}
h3 {
  padding: 20px 0 10px 0;
  text-align: left;
}
.qgfys {
  display: flex;
  align-items: center;
  //padding-left: 40px;
  .sr {
    width: 120px;
  }
  p{padding-right: 10px;padding-left: 20px;}
  p:nth-child(1) {padding-left: 0;}
}
.ybbs {
  display: flex;
  align-items: center;
  text-align: left;
  .ybbs-box {
    padding-right: 100px;
    .ybbs-box-nr {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      p {
        width: 160px;
      }
    }
  }
}
.qtjbs {
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  h3 {
    padding: 0;
    width: 200px;
  }
  .sr {width: 300px;}
}
.db {
  padding: 50px 0 20px 0;
}
</style>
